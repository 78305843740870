/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {


        setTimeout(function () {
          $('.footer').addClass('expanded');
          //$('.footer-close').addClass('cross');
        }, 5000);


        $('.js--newsletter-close').click(function(e) {
          e.preventDefault();
          $('.footer').removeClass('expanded');  
        })
$('.js--scroll').click(function(e) {
  e.preventDefault();
  $('html, body').animate({
    scrollTop: $(window).innerHeight()
  }, 400, function(){

  })
})

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {


        function preload(imageArray, index) {
          index = index || 0;
          if (imageArray && imageArray.length > index) {
            var img = new Image();
            img.onload = function () {
              preload(imageArray, index + 1);
            }
            img.src = images[index];
          }
        }
        var postfix;
        /* images is an array with image metadata */
        var images = [];
        var numImages = $('.kaleidoscope').data('numimages');
        if ($(window).innerWidth() < 800) {
	        	        for(var i=1; i<=numImages; i++) {
	        		        images.push('/backgroundImages/background-'+i+'--mobile.jpg');
}

          postfix = '--mobile';
        } else {
	        for(var i=1; i<= numImages; i++) {
		        images.push('/backgroundImages/background-'+i+'.jpg');
	        }
          postfix = '';
        }
        preload(images);
        $('.js--footer-close').click(function (e) {
          e.preventDefault();
          if ($(this).hasClass('cross')) {
            $('.footer').removeClass('expanded');
            $('.footer-close').removeClass('cross');

          } else {
            $('.footer').addClass('expanded');
            $('.footer-close').addClass('cross');
          }
        });
        window.currentImage = 1;
        $('.kaleidoscope').on('click', function () {
          var $kaleidescope = $('.kaleidoscope');
          var $image = $kaleidescope.find('.image');
          var currentImage = window.currentImage;

          if (currentImage == 4) {
            $image.css('background-image', ['url(/backgroundImages/background-1' + postfix + '.jpg)']);
            window.currentImage = 1;
          } else {
            var newImage = currentImage + 1;
            $image.css('background-image', ['url(/backgroundImages/background-' + newImage + postfix + '.jpg)']);
            window.currentImage = newImage;
          }

        })

        $('.footer-signup-submit').click(function (e) {
          e.preventDefault();
          $.ajax({
            url: '/register.php',
            type: 'post',
            dataType: 'json',
            success: function () {
              //$('#target').html(data.msg);
              /*
              if (data == 'subscribed') {

                  $('.js--form-messages').stop(true, true).fadeOut(function() {
                      $('.js--form-messages').html('Thank you');
                      $('.js--form-messages').stop(true, true).fadeIn();


                  });
              } else {
                  $('.js--form-messages').stop(true, true).fadeOut(function() {
                      $('.js--modal-email').val('').attr('placeholder', 'Try again').fadeIn();
                      $('.js--modal-submit').stop(true, true).fadeIn()
                  });
              }*/
              $('.footer-newsletter').height($('.footer-newsletter').height());
              $('.footer-signup').fadeOut(function () {
                $('.signup-thankyou').fadeIn();
              });

            },
            data: {
              'email': $('.footer-signup-email').val()
            }
          });
        })



      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
